<template>
  <div class="container px-4 pt-10 mx-auto">
    <div>
      <h2
        class="pb-6 text-lg font-semibold tracking-wider uppercase  text-myyellow"
      >
        TV Shows
      </h2>
      <div
        class="grid grid-cols-1 gap-8  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5"
      >
        <div class="mt-3" v-for="tvShow in tvShowList" :key="tvShow.ratingKey">
          <tv-show-card v-if="tvShow" :tvShow="tvShow" :genres="tvShow.Genre" />
        </div>
      </div>
    </div>
    <div v-if="loading"><loading-bar /></div>
    <jw-pagination
      v-if="tvShows.length > 0"
      :items="tvShows"
      @changePage="onChangePage"
      :pageSize="10"
    ></jw-pagination>
  </div>
</template>
<script>
import TvShowCard from '../TvShowCard.vue'
import { onBeforeMount, ref } from 'vue'
import LoadingBar from '../../LoadingBar.vue'
import getTvShows from '../composables/getTvShows'
import JwPagination from '../../JwPagination.vue'
export default {
  props: [],
  components: {
    TvShowCard,
    LoadingBar,
    JwPagination
  },
  setup () {
    const { tvShows, loading, loadTvShows } = getTvShows()
    const tvShowList = ref([])

    onBeforeMount(() => {
      loadTvShows()
    })

    const onChangePage = async pageOfItems => {
      tvShowList.value = await pageOfItems
    }
    
    return { tvShows, tvShowList, loading, onChangePage }
  }
}
</script>
<style></style>
