import axios from 'axios';
import { useStore } from "vuex"
import { ref, computed } from 'vue';

const getTvShows = () => {
  const store = useStore()
  let tvShows = computed(function () {
    return store.state.tvShows
  })
  const loading = ref(false)
  const loadTvShows = async () => {
    if (tvShows.value.length > 0) {
      loading.value = false;
    } else {
      loading.value = true;
      axios.get(`https://plex.nzbhorizon.com/library/sections/2/all?X-Plex-Token=${process.env.VUE_APP_X_Plex_Token}`)
        .then(res => {
          res.data.MediaContainer.Metadata.forEach(t => {
            t.X_Plex_Token = process.env.VUE_APP_X_Plex_Token
          })
          store.commit('setTvShows', res.data.MediaContainer.Metadata)
          loading.value = false;
        }).catch(err => {
          loading.value = false;
        });      
    }

  }

  return { tvShows, loading, loadTvShows };
}

export default getTvShows;
